import { saveAs } from 'file-saver';
import Highcharts from 'highcharts';
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { Dropdown, OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import DatePicker from 'react-datepicker';
import { BsInfoCircle, BsInfoSquare } from "react-icons/bs";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import styled from "styled-components";
import { Edit, ExclaimationCircle, Gear, Note } from "../../Components/ActionBtns";
import NotesModal from "../../Components/NoteModal";
import { CallAxios, CallAxiosBlob } from "../../Helpers";
import { TableWrapper } from "../../Helpers/Global/StyledComponents";
import { Constants } from '../../Helpers/Contents';
import { useTranslation } from "react-i18next";

const FiltersWrapper = styled.div`
& .mb-0{
    margin-right:5px;
    margin-bottom:5px;
    display:inline-block;
}
`
function RecordsTab() {
    const { t } = useTranslation('myProfile');
    const { t: ct } = useTranslation('common');
    const { t: sdt } = useTranslation('selectDropdown');


    const { userid } = useParams();
    const user = useSelector(x => x.app.user)
    const appSettings = useSelector(x => x.app.appSettings)
    const currentView = useSelector(x => x.app.currentView)
    const [showNoteModal, setShowNoteModal] = useState(false);
    const [EmpRecordId, setEmpRecordId] = useState();
    const [filterstartDate, setfilterstartDate] = useState(new Date(moment().startOf("month")));
    const [filterEndDate, setfilterEndDate] = useState(new Date(moment()));
    const [recordId, setRecordId] = useState("");

    const [RecordsList, setRecordsList] = useState([]);
    const [Absent, setAbsent] = useState(0.0);
    const [Actual, setActual] = useState(0.0);
    const [Currentbalance, setCurrentbalance] = useState(0.0);
    const [Plan, setPlan] = useState(0.0);
    const [Target, setTarget] = useState(0.0);

    const [editMode, setEditMode] = useState(false);
    const [starOfpayroll, setstarOfpayroll] = useState(null);
    const [StartBalance, setStartBalance] = useState(0.0);
    const [HourlyWages, setHourlyWages] = useState(0.0);
    const [CustomHoursAbsenceDay, setCustomHoursAbsenceDay] = useState(0.0);
    const [Publicholidays, setPublicholidays] = useState("Calculate_using_target_hours_on_public_holidays");
    const [CalculatedHoursonHolidays, setCalculatedHoursonHolidays] = useState(0.0);
    const [CurrentBalance, setCurrentBalance] = useState("Actual-Target");

    const [RecordToBeEdit, setRecordToBeEdit] = useState({});
    const [ShowEditModel, setShowEditModel] = useState(false);
    const themeColor = useSelector(state => state.app.theme.colors.buttons);

    useEffect(() => {
        getDate();
        getRecord();
    }, []);

    const Submit = async () => {
        var data = {
            EmpId: userid.toString(),
            EmpRecordId: EmpRecordId,
            starOfpayroll: starOfpayroll,
            StartBalance: parseFloat(StartBalance),
            HourlyWages: parseFloat(HourlyWages),
            CustomHoursAbsenceDay: parseFloat(CustomHoursAbsenceDay),
            Publicholidays: Publicholidays,
            CalculatedHoursonHolidays: parseFloat(CalculatedHoursonHolidays),
            CurrentBalance: CurrentBalance,
        }
        var response = await CallAxios("/api/EmployeeRecord/UpdateEmployeeRecord|post", data)
        if (!!response && response.status === 200) {
            setEditMode(false)
        }
    }
    const getDate = async () => {
        var response = await CallAxios("/api/EmployeeRecord/GetEmployeeRecord|post", { id: userid.toString() })
        if (!!response && response.status === 200) {
            setstarOfpayroll(response.data.starOfpayroll)
            setStartBalance(response.data.startBalance)
            setHourlyWages(response.data.hourlyWages)
            setCustomHoursAbsenceDay(response.data.customHoursAbsenceDay)
            setPublicholidays(response.data.publicholidays)
            setCalculatedHoursonHolidays(response.data.calculatedHoursonHolidays)
            setCurrentBalance(response.data.currentBalance)
            setEmpRecordId(response.data.empRecordId)
        }
    }
    const getRecord = async () => {
        var data = {
            EmpId: userid.toString(),
            SatrtDate: moment(filterstartDate).startOf("day"),
            EndDate: moment(filterEndDate).startOf("day"),
        }
        var response = await CallAxios("/api/EmployeeRecord/GetEmployeeRecordList|post", data)
        if (!!response && response.status === 200) {
            setRecordsList(response.data.employeeRecordsList)
            setAbsent(response.data.absent)
            setActual(response.data.actual)
            setCurrentbalance(response.data.currentbalance)
            setPlan(response.data.plan)
            setTarget(response.data.target)
        }
    }
    const EditModel = (recordId) => {
        setRecordToBeEdit(RecordsList.find(x => x.recordId === recordId))
        setShowEditModel(true)
    }
    const ExportRecord = async (format) => {

        var data = {
            EmpId: userid.toString(),
            format: format,
            SatrtDate: moment(filterstartDate).startOf("day"),
            EndDate: moment(filterEndDate).startOf("day"),
        }
        var response = await CallAxiosBlob("/api/EmployeeRecord/ExportEmployeeRecords|post", data);

        if (!!response && response.status === 200) {
            if (format === 'CSV') {
                saveAs(response.data, "EmplyeeRecord.csv")
            }
            else if (format === 'XLSX') {
                saveAs(response.data, "EmplyeeRecord.xlsx")
            }
        }
    }

    return <>
        <div className="DetailInfoWrapper">
            <Container fluid>
                <Row>
                    <Col md={3}>
                        <div className='mb-3 d-flex justify-content-between align-items-center'>
                            <h4 className='h5 fw-bold mb-0'> {t('records')}</h4>
                        </div>

                        {/* {editMode === false ?
                            <>

                                <div className="UiInfoFlex">
                                    <div className="InfoLabel">Start of Payroll:</div>
                                    <div className="InfoValue">{moment(starOfpayroll).format("DD/MM/YYYY")}</div>
                                </div>
                                <div className="UiInfoFlex">
                                    <div className="InfoLabel">Balance (start):</div>
                                    <div className="InfoValue">{StartBalance}</div>
                                </div>
                                <div className="UiInfoFlex">
                                    <div className="InfoLabel">Hourly Wages:</div>
                                    <div className="InfoValue">{HourlyWages}</div>
                                </div>
                                <div className="UiInfoFlex">
                                    <div className="InfoLabel">Custom hours on absent day:</div>
                                    <div className="InfoValue">{CustomHoursAbsenceDay}</div>
                                </div>
                                <div className="UiInfoFlex">
                                    <div className="InfoLabel">Public holidays:</div>
                                    <div className="InfoValue">
                                        {Publicholidays === "Set_target_hours_on_public_holidays_to_zero" ? "Set target hours to zero" :
                                            Publicholidays === "Calculate_using_target_hours_on_public_holidays" ? "Calculate with target hours" :
                                                Publicholidays === "Calculate_using_at_least_target_hours_on_public_holidays" ? "Calculate using at least target hours on public" :
                                                    Publicholidays === "Calculate_using_defined_value_on_public_holidays" ? "Calculate with " + CalculatedHoursonHolidays + " hours" : ""
                                        }
                                    </div>

                                </div>
                                <div className="UiInfoFlex">
                                    <div className="InfoLabel">Current balance:</div>
                                    <div className="InfoValue">{CurrentBalance}</div>
                                </div>
                                <div className="UiInfoFlex">

                                    <div className="InfoValue">
                                        <a href='#' onClick={(e) => setEditMode(true)} >Edit</a>
                                    </div>
                                </div>
                            </>
                            : null
                        } */}

                    </Col>

                    <Col md={6}>
                        <Row className="dateBoxDesgin">
                            <Col md={4}>
                                <Form.Group controlId="ExportForm.txtWeekDateResource">
                                    <DatePicker className="form-control" minDate={new Date(starOfpayroll)} maxDate={new Date(new Date().setFullYear(new Date().getFullYear() + 3))} selected={filterstartDate} onChange={(date) => setfilterstartDate(date)} />
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group controlId="ExportForm.txtWeekDateResource">
                                    <DatePicker className="form-control" minDate={filterstartDate} maxDate={new Date(new Date().setFullYear(new Date().getFullYear() + 3))} selected={filterEndDate} onChange={(date) => setfilterEndDate(date)} />
                                </Form.Group>
                            </Col>
                            <Col md={1}>
                                <Button onClick={() => getRecord()}> {ct('show')}</Button>
                            </Col>
                        </Row>
                    </Col>

                    <Col md={3} className="dateBoxDesgin d-flex justify-content-end">
                        <Dropdown>
                            <Dropdown.Toggle variant="primary">
                                {ct('export')}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item href="#" onClick={() => ExportRecord("XLSX")}> {ct('excel')}</Dropdown.Item>
                                <Dropdown.Item href="#" onClick={() => ExportRecord("CSV")}> {ct('csv')}</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                    <Col md={12}>
                        {/* {editMode === true ? */}
                        <FiltersWrapper className=" mb-3 mt-2 ">

                            <Form.Group className="mb-0" controlId="AddAbsenceForm.txtCorrected hours">
                                <Form.Label> {t('recordTab.payrollStarts')}:</Form.Label>
                                <DatePicker disabled={!editMode} className="form-control" selected={new Date(starOfpayroll)} onChange={(date) => setstarOfpayroll(date)} />
                            </Form.Group>
                            <Form.Group className="mb-0" controlId="AddAbsenceForm.txtCorrected hours">
                                <Form.Label> {t('recordTab.startingBalance')}</Form.Label>
                                <Form.Control disabled={!editMode} type="text" maxLength={150} value={StartBalance} onChange={(e) => setStartBalance(e.target.value)} />
                            </Form.Group>
                            <Form.Group className="mb-0" controlId="AddAbsenceForm.txtCorrected hours">
                                <Form.Label>  {t('recordTab.hourlyWage')}</Form.Label>
                                <Form.Control disabled={!editMode} type="text" maxLength={150} value={HourlyWages} onChange={(e) => setHourlyWages(e.target.value)} />
                            </Form.Group>
                            <Form.Group className="mb-0" controlId="AddAbsenceForm.txtCorrected hours">
                                <Form.Label>  {t('recordTab.customHoursAbsent')}</Form.Label>
                                <Form.Control disabled={!editMode} type="text" value={CustomHoursAbsenceDay} maxLength={150} onChange={(e) => setCustomHoursAbsenceDay(e.target.value)} />
                            </Form.Group>
                            <Form.Group className="mb-0" controlId="AddAbsenceForm.txtCorrected hours">
                                <Form.Label> {ct('publicHolidays')}</Form.Label>
                                <Form.Control disabled={!editMode} className="form-select" as="select" value={Publicholidays} onChange={(e) => setPublicholidays(e.target.value)}>
                                    <option value="Set_target_hours_on_public_holidays_to_zero"> {sdt('recordTabSelect.publicHoliday.option1')} </option>
                                    <option value="Calculate_using_target_hours_on_public_holidays"> {sdt('recordTabSelect.publicHoliday.option2')}</option>
                                    <option value="Calculate_using_at_least_target_hours_on_public_holidays"> {sdt('recordTabSelect.publicHoliday.option3')}</option>
                                    <option value="Calculate_using_defined_value_on_public_holidays"> {sdt('recordTabSelect.publicHoliday.option4')}</option>
                                </Form.Control>
                            </Form.Group>

                            {/* <Form.Group className="mb-0">
                                    <Form.Check
                                        inline
                                        label="Set target hours on public holidays to zero"
                                        name="Publicholidays"
                                        checked={Publicholidays === "Set_target_hours_on_public_holidays_to_zero" ? true : false}
                                        type={"radio"}
                                        value="Set_target_hours_on_public_holidays_to_zero"
                                        id={`inline-radio-2`}
                                        onChange={(e) => setPublicholidays(e.target.value)}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-0">
                                    <Form.Check
                                        inline
                                        label="Calculate using target hours on public holidays"
                                        name="Publicholidays"
                                        checked={Publicholidays === "Calculate_using_target_hours_on_public_holidays" ? true : false}
                                        type={"radio"}
                                        value="Calculate_using_target_hours_on_public_holidays"
                                        id={`inline-radio-2`}
                                        onChange={(e) => setPublicholidays(e.target.value)}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-0">
                                    <Form.Check
                                        inline
                                        label="Calculate using at least target hours on public holidays"
                                        name="Publicholidays"
                                        checked={Publicholidays === "Calculate_using_at_least_target_hours_on_public_holidays" ? true : false}
                                        type={"radio"}
                                        value="Calculate_using_at_least_target_hours_on_public_holidays"
                                        id={`inline-radio-2`}
                                        onChange={(e) => setPublicholidays(e.target.value)}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-0">
                                    <Form.Check
                                        inline
                                        label="Calculate using defined value on public holidays"
                                        name="Publicholidays"
                                        checked={Publicholidays === "Calculate_using_defined_value_on_public_holidays" ? true : false}
                                        type={"radio"}
                                        value="Calculate_using_defined_value_on_public_holidays"
                                        id={`inline-radio-2`}
                                        onChange={(e) => setPublicholidays(e.target.value)}
                                    />
                                </Form.Group> */}


                            <Form.Group className="mb-0" controlId="AddAbsenceForm.txtCorrected hours">
                                <Form.Label> {t('recordTab.currentBalance')}:</Form.Label>
                                <Form.Control disabled={!editMode} as="select" className="Noarrow bgPrimaryGrey" value={CurrentBalance} onChange={(e) => setCurrentBalance(e.target.value)} custom>
                                    <option value='Actual-Target'> {sdt('recordTabSelect.currentBalance.option1')}</option>
                                    <option value='Actual-Plan'> {sdt('recordTabSelect.currentBalance.option2')}</option>
                                    <option value='Plan-Target'> {sdt('recordTabSelect.currentBalance.option3')}</option>
                                </Form.Control>
                            </Form.Group>
                            <div className="UiInfoFlex mb-0 mt-2">
                                {editMode === false ? <Edit color={themeColor} className="ActionIcon" onClick={() => setEditMode(true)} /> :
                                    <Button onClick={(e) => Submit()}>{ct('done')}</Button>}
                            </div>
                        </FiltersWrapper>
                        {/* : null */}
                        {/* } */}
                    </Col>
                    <Col md={3}>
                        {Publicholidays === "Calculate_using_defined_value_on_public_holidays" && <Form.Group className="mb-3" controlId="AddAbsenceForm.txtCorrected hours">
                            <Form.Label className='text-nowrap'> {t('recordTab.calculatedHoursHolidays')} </Form.Label>
                            <Form.Control type="text" value={CalculatedHoursonHolidays} disabled={Publicholidays === "Calculate_using_defined_value_on_public_holidays" ? false : true} onChange={(e) => setCalculatedHoursonHolidays(e.target.value)} />
                        </Form.Group>
                        }
                    </Col>
                </Row >
            </Container >
            <Col md={12}>
                <TableWrapper>
                    <Table striped bordered responsive className={"tbl-emp-evaluation"}>
                        <thead>
                            <tr className='firsthead'>
                                <th>{ct('day')}</th>
                                <th>{t('recordTab.table.target')}
                                    {/* <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={<Tooltip></Tooltip>}> */}
                                    <ExclaimationCircle data-tooltip-id="my-tooltip" data-tooltip-content={t('recordTab.table.targetTooltip')} color='#fff' fs="14px" />
                                    {/* </OverlayTrigger> */}
                                </th>
                                <th>{t('recordTab.table.plan')}
                                    {/* <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={<Tooltip></Tooltip>}> */}
                                    <ExclaimationCircle data-tooltip-id="my-tooltip" data-tooltip-content={t('recordTab.table.planTooltip')} color='#fff' fs="14px" />
                                    {/* </OverlayTrigger> */}
                                </th>
                                <th>{t('recordTab.table.actual')}
                                    {/* <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={<Tooltip></Tooltip>}> */}
                                    <ExclaimationCircle data-tooltip-id="my-tooltip" data-tooltip-content={t('recordTab.table.actualTooltip')} color='#fff' fs="14px" />
                                    {/* </OverlayTrigger>  */}
                                </th>
                                <th>{t('recordTab.table.absent')}
                                    {/* <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={<Tooltip></Tooltip>}> */}
                                    <ExclaimationCircle data-tooltip-id="my-tooltip" data-tooltip-content={t('recordTab.table.absentTooltip')} color='#fff' fs="14px" />
                                    {/* </OverlayTrigger> */}
                                </th>
                                <th>{t('recordTab.table.difference')}
                                    {/* <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={<Tooltip></Tooltip>}> */}
                                    <ExclaimationCircle data-tooltip-id="my-tooltip" data-tooltip-content={t('recordTab.table.differencetooltip')} color='#fff' fs="14px" />
                                    {/* </OverlayTrigger> */}
                                </th>
                                <th>{t('recordTab.table.compensation')}</th>
                                <th>{t('recordTab.currentBalance')}</th>
                                {
                                    currentView === Constants.layoutType.Admin &&
                                    <th> {ct('actions')}</th>
                                }
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{t('recordTab.startingBalance')}</td>
                                <td>{Target.toFixed(2)}</td>
                                <td>{Plan.toFixed(2)}</td>
                                <td>{Actual.toFixed(2)}</td>
                                <td>{Absent.toFixed(2)}</td>
                                <td></td>
                                <td></td>
                                <td>{Currentbalance.toFixed(2)}</td>
                                {
                                    currentView === Constants.layoutType.Admin &&
                                    <td></td>
                                }
                            </tr>
                            {
                                RecordsList.length > 0 ? RecordsList.map((value, key) => {
                                    return <tr>
                                        <td>{moment(value.day).format("ddd DD/MM")}
                                            {
                                                !!value.publicHoliday ?
                                                    // value.isHalfDay ?
                                                    <sup class="highlight_holiday" style={{ "backgroundColor": "#5bc0de" }} data-tooltip-id="my-tooltip" data-tooltip-content="Public holiday"><span class="label label-info">h</span></sup>
                                                    // :
                                                    // <BsInfoSquare data-tooltip-id="my-tooltip" data-tooltip-content={value.publicHoliday} />
                                                    : null
                                            }
                                        </td>
                                        <td>{value.target.toFixed(2)}</td>
                                        <td>{value.plan.toFixed(2)}</td>
                                        <td>{value.actual.toFixed(2)}</td>
                                        <td>{value.absent.toFixed(2)}
                                            {
                                                !!value.absentType ?
                                                    // <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={<Tooltip></Tooltip>}>
                                                    <BsInfoCircle data-tooltip-id="my-tooltip" data-tooltip-content={value.absentType} />
                                                    // </OverlayTrigger>
                                                    : null
                                            }
                                        </td>
                                        <td>{(value.difference < 0 ? " " : "+ ")}{value.difference.toFixed(2)}</td>
                                        <td>{value.compensation.toFixed(2)}</td>
                                        <td>{value.currentbalance.toFixed(2)}</td>
                                        {
                                            currentView === Constants.layoutType.Admin &&
                                            <>
                                                <td className="d-flex ">
                                                    <Gear className="btnTblAction bgBlue" onClick={(e) => EditModel(value.recordId)} />
                                                    <Note className="btnTblAction bgYellow" onClick={(e) => { setRecordId(value.recordId); setShowNoteModal(true) }} />
                                                </td>
                                            </>
                                        }
                                    </tr>
                                })
                                    : null
                            }
                            {
                                RecordsList.length > 0 ?
                                    <tr>
                                        <td>{t('recordTab.startingBalance')}</td>
                                        <td>{Target.toFixed(2)}</td>
                                        <td>{Plan.toFixed(2)}</td>
                                        <td>{Actual.toFixed(2)}</td>
                                        <td>{Absent.toFixed(2)}</td>
                                        <td></td>
                                        <td></td>
                                        <td>{Currentbalance.toFixed(2)}</td>
                                        <td></td>
                                    </tr>
                                    : null
                            }
                            {
                                RecordsList.length > 0 ?
                                    <tr>
                                        <td>{moment(RecordsList[0].day).format("DD/MM")} - {moment(RecordsList[RecordsList.length - 1].day).format("DD/MM")}</td>
                                        <td>{(RecordsList.reduce(function (prev, current) {
                                            return prev + current.target
                                        }, 0) + Target).toFixed(2)}
                                        </td>
                                        <td>{(RecordsList.reduce(function (prev, current) {
                                            return prev + current.plan
                                        }, 0) + Plan).toFixed(2)}
                                        </td>
                                        <td>{(RecordsList.reduce(function (prev, current) {
                                            return prev + current.actual
                                        }, 0) + Actual).toFixed(2)}
                                        </td>
                                        <td>{(RecordsList.reduce(function (prev, current) {
                                            return prev + current.absent
                                        }, 0) + Absent).toFixed(2)}
                                        </td>
                                        <td></td>
                                        <td>{(RecordsList.reduce(function (prev, current) {
                                            return prev + current.compensation
                                        }, 0)).toFixed(2)}
                                        </td>
                                        <td></td>
                                        <td>
                                        </td>
                                    </tr>
                                    : null
                            }
                        </tbody>

                    </Table >
                </TableWrapper>
            </Col >

        </div >
        <RecordsChart RecordsList={RecordsList} />
        {
            showNoteModal === true ?
                <RecordNoteModel show={showNoteModal} onHide={setShowNoteModal} recordId={recordId} />
                : null
        }
        {
            ShowEditModel ?
                <EditRecordModal show={ShowEditModel} onhide={setShowEditModel} record={RecordToBeEdit} reloaddata={getRecord} />
                : null
        }
    </>
}

export default RecordsTab;
function EditRecordModal(props) {
    const { t } = useTranslation('myProfile');
    const { t: ct } = useTranslation('common');


    useEffect(() => {
        setAbsencecalc(props.record.absencecalc)
        setAbsencebalance(props.record.absent)
        setCompensation(props.record.compensation)

    }, []);
    const [Compensation, setCompensation] = useState(0.0);
    const [Absencecalc, setAbsencecalc] = useState(0.0);
    const [Absencebalance, setAbsencebalance] = useState(0.0);

    const UpdateRecord = async () => {

        var data = {
            Id: props.record.recordId,
            Compansation: parseFloat(Compensation),
            Absencecalc: parseFloat(Absencecalc),
            // Absence: parseFloat(Absencecalc),
        }
        var response = await CallAxios("/api/EmployeeRecord/UpdateEmpRecors|post", data)
        if (!!response && response.status === 200) {
            props.reloaddata()
            props.onhide(false)
        }
    }
    return (
        <>
            <Modal show={props.show} onHide={() => props.onhide(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('recordTab.targetPlanMdl.title')} <b>{moment(props.record.day).format("DD.MM.YYYY")}</b> {t('recordTab.targetPlanMdl.edit')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className="pb-3">
                        <Col md={4}>
                            <Form.Group className="">
                                <Form.Label> {t('recordTab.targetPlanMdl.targeted')}</Form.Label>
                                <Form.Control type="text" placeholder="0" disabled={true} defaultValue={props.record.target} />
                            </Form.Group>
                        </Col>
                        <Col md={4}>
                            <Form.Group className="">
                                <Form.Label> {t('recordTab.targetPlanMdl.planned')}</Form.Label>
                                <Form.Control type="text" placeholder="0" disabled={true} defaultValue={props.record.plan} />
                            </Form.Group>
                        </Col>
                        <Col md={4}>
                            <Form.Group className="">
                                <Form.Label> {t('recordTab.targetPlanMdl.actual')}</Form.Label>
                                <Form.Control type="text" placeholder="0" disabled={true} defaultValue={props.record.actual} />
                            </Form.Group>
                        </Col>
                        <Col md={4}>
                            <Form.Group className="">
                                <Form.Label> {t('recordTab.targetPlanMdl.compensation')}</Form.Label>
                                <Form.Control type="text" placeholder="0" onChange={(e) => setCompensation(e.target.value)} value={Compensation} />
                            </Form.Group>
                        </Col>
                        <Col md={4}>
                            <Form.Group className="">
                                <Form.Label> {t('recordTab.targetPlanMdl.absenceBalance')}</Form.Label>
                                <Form.Control type="text" placeholder="0" disabled={true} onChange={(e) => setAbsencebalance(e.target.value)} value={Absencebalance} />
                            </Form.Group >
                        </Col >
                        <Col md={4}>
                            <Form.Group className="">
                                <Form.Label> {t('recordTab.targetPlanMdl.absenceCalc')}</Form.Label>
                                <Form.Control type="text" placeholder="0" onChange={(e) => setAbsencecalc(e.target.value)} value={Absencecalc} />
                            </Form.Group>
                        </Col>
                    </Row >
                </Modal.Body >
                <Modal.Footer>
                    <Button className="btn btn-secondary" onClick={() => UpdateRecord()}>{ct('done')}</Button>
                </Modal.Footer>
            </Modal >
        </>
    )
}
function RecordsChart(props) {
    const { t } = useTranslation('myProfile');

    const chartdiv = useRef();
    const chart = useRef();
    const [DataList, setDataList] = useState([]);
    useEffect(() => {
        if (!!props.RecordsList) {
            var list = [
                {
                    name: 'Target',
                    data: [],
                    marker: {
                        symbol: "circle"
                    },
                    color: "#27ae60"
                },
                {
                    name: 'Plan',
                    data: [],
                    marker: {
                        symbol: "circle"
                    },
                    color: "#f1c40f"
                },
                {
                    name: 'Actual',
                    data: [],
                    marker: {
                        symbol: "circle"
                    },
                    color: "#c0392b"
                },
            ];

            props.RecordsList.map((val, key) => {
                list.find(x => x.name === 'Target').data.push({ x: new Date(val.day).getTime(), y: val.target })
                list.find(x => x.name === 'Plan').data.push({ x: new Date(val.day).getTime(), y: val.plan })
                list.find(x => x.name === 'Actual').data.push({ x: new Date(val.day).getTime(), y: val.actual })
            })
            setDataList(list)
        }
    }, [props.RecordsList]);

    useEffect(() => {
        chart.current = Highcharts.chart(chartdiv.current, {

            legend: {
                layout: 'vertical',
                align: 'right',
                verticalAlign: 'middle'
            },
            title: {
                text: ''
            },
            chart: {
                backgroundColor: 'transparent',
            },
            yAxis: {
                title: {
                    text: ''
                }
            },
            xAxis: {
                type: 'datetime',

            },
            plotOptions: {
                line: {
                    enableMouseTracking: true
                },
                series: {
                    label: {
                        connectorAllowed: false
                    },
                    // pointStart: Date.now(2010, 0, 1),
                    pointInterval: 24 * 3600 * 1000
                }
            },
            tooltip: {
                useHTML: true,
                shared: true,
                borderRadius: 15,
                backgroundColor: "#fff",
                borderColor: "#ccc",
                shadow: false,
                formatter: function () {

                    return `<div class=" text-center">${new Date(this.x).toLocaleDateString()}</div> 
                          <div class=" text-center" style="color:${this?.points[0].color}"> Target: ${this?.points[0].y}</div> 
                          <div class=" text-center" style="color:${this?.points[1].color}"> Plan: ${this?.points[1].y}</div> 
                          <div class=" text-center" style="color:${this?.points[2].color}"> Actual: ${this?.points[2].y}</div>
                          `
                }
            },
            series:
                [
                    {
                        name: t('recordTab.table.target').toString(),
                        data: [],
                        visible: true,

                        marker: {
                            symbol: "circle"
                        },
                        color: "#27ae60"
                    },
                    {
                        name: t('recordTab.table.plan').toString(),
                        data: [],
                        visible: true,

                        marker: {
                            symbol: "circle"
                        },
                        color: "#f1c40f"
                    },
                    {
                        name: t('recordTab.table.actual').toString(),
                        data: [],
                        visible: true,

                        marker: {
                            symbol: "circle"
                        },
                        color: "#c0392b"
                    },
                ],

            responsive: {
                rules: [{
                    condition: {
                        maxWidth: 500
                    },
                    chartOptions: {
                        legend: {
                            layout: 'horizontal',
                            align: 'center',
                            verticalAlign: 'bottom'
                        }
                    }
                }]
            }

        });
    }, []);

    useEffect(() => {
        if (chart.current) {
            if (DataList.length > 0) {
                DataList.forEach(function (p, i) {
                    p.data.map(x => chart.current.series[i].addPoint(x, false))

                });
                chart.current.redraw();
            }

        }

    }, [chart.current, DataList]);
    return (
        <>
            <div ref={chartdiv} style={{ height: "400px" }}></div>
        </>
    );
}

function RecordNoteModel(props) {
    const { t: ct } = useTranslation('common');


    return (
        <Modal show={props.show} onHide={() => props.onHide(false)}>
            <Modal.Header closeButton >
                <Modal.Title> {ct('note')} </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Row>
                        <Col md={12}>
                            <Container fluid>
                                <NotesModal objIdforNote={props.recordId} noteEntity="Records" />
                            </Container>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button className="btn btn-secondary" onClick={() => props.onHide(false)}> {ct('shutdown')}</Button>
            </Modal.Footer>
        </Modal>
    )
}

